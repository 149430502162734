import React, { cloneElement } from 'react';
import { setupI18n, number, date } from '@lingui/core';
import PropTypes from 'prop-types';
import hashSum from 'hash-sum';
import hoistStatics from 'hoist-non-react-statics';

/*
 * I18nPublisher - Connects to lingui-i18n/I18n class
 * Allows listeners to subscribe for changes
 */

function makeLinguiPublisher(i18n) {
    let subscribers = [];

    return {
        i18n,
        i18nHash: null,

        getSubscribers() {
            return subscribers;
        },

        subscribe(callback) {
            subscribers.push(callback);
        },

        unsubscribe(callback) {
            subscribers = subscribers.filter(cb => cb !== callback);
        },

        update(params = {}) {
            if (!params) return;
            const { catalogs, language, locales } = params;
            if (!catalogs && !language && !locales) return;

            if (catalogs) i18n.load(catalogs);
            if (language) i18n.activate(language, locales);

            this.i18nHash = hashSum([i18n.language, i18n.messages]);

            subscribers.forEach(f => f());
        }
    };
}

class I18nProvider extends React.Component {
    static defaultProps = {
        defaultRender: null
    };

    static childContextTypes = {
        linguiPublisher: PropTypes.object.isRequired,
        linguiDefaultRender: PropTypes.any
    };

    constructor(props) {
        super(props);
        const { language, locales, catalogs, missing, i18n: i18nProp } =
            this.props || {};
        const i18n =
            i18nProp ||
            setupI18n({
                language,
                locales,
                catalogs
            });
        this.linguiPublisher = makeLinguiPublisher(i18n);
        this.linguiPublisher.i18n._missing = missing;
    }

    componentDidUpdate(prevProps) {
        const { language, locales, catalogs, missing } = this.props || {};
        if (
            language !== prevProps.language ||
            locales !== prevProps.locales ||
            catalogs !== prevProps.catalogs
        ) {
            this.linguiPublisher.update({ language, catalogs, locales });
        }

        this.linguiPublisher.i18n._missing = missing;
    }

    getChildContext() {
        const { defaultRender } = this.props || {};
        return {
            linguiPublisher: this.linguiPublisher,
            linguiDefaultRender: defaultRender
        };
    }

    render() {
        const { children } = this.props || {};
        return children || null;
    }
}

class I18n extends React.Component {
    static defaultProps = {
        update: true,
        withHash: true
    };

    static contextTypes = {
        linguiPublisher: PropTypes.object
    };

    componentDidMount() {
        const { subscribe } = this.getI18n();
        if (this.props.update && subscribe) subscribe(this.checkUpdate);
    }

    componentWillUnmount() {
        const { unsubscribe } = this.getI18n();
        if (this.props.update && unsubscribe) unsubscribe(this.checkUpdate);
    }

    // Test checks that subscribe/unsubscribe is called with function.
    checkUpdate = /* istanbul ignore next */ () => {
        this.forceUpdate();
    };

    getI18n() {
        return this.context.linguiPublisher || {};
    }

    render() {
        const { children, withHash } = this.props || {};
        const { i18n, i18nHash } = this.getI18n();
        const props = {
            i18n,
            // Add hash of active language and active catalog, so underlying
            // PureComponent is forced to rerender.
            ...(withHash ? { i18nHash } : {})
        };

        if (typeof children === 'function') {
            return children(props);
        }

        if (process.env.NODE_ENV !== 'production') {
            console.warn(
                'I18n accepts only function as a children. ' +
                    'Other usecases are deprecated and will be removed in v3.0'
            );
        }

        // Deprecate v3.0
        return React.isValidElement(children)
            ? React.cloneElement(children, props)
            : React.createElement(children, props);
    }
}

const withI18n = (options = {}) =>
    function(WrappedComponent) {
        if (process.env.NODE_ENV !== 'production') {
            if (
                typeof options === 'function' ||
                React.isValidElement(options)
            ) {
                console.warn(
                    'withI18n([options]) takes options as a first argument, ' +
                        'but received React component itself. Without options, the Component ' +
                        'should be wrapped as withI18n()(Component), not withI18n(Component).'
                );
            }
        }

        const { update = true, withHash = true, withRef = false } = options;

        class WithI18n extends React.Component {
            static contextTypes = {
                linguiPublisher: PropTypes.object
            };

            wrappedInstance = null;

            setWrappedInstance = ref => {
                if (withRef) this.wrappedInstance = ref;
            };

            getWrappedInstance = () => {
                if (!withRef) {
                    throw new Error(
                        'To access the wrapped instance, you need to specify { withRef: true }' +
                            ' in the options argument of the withI18n() call.'
                    );
                }

                return this.wrappedInstance;
            };

            render() {
                const props = {
                    ...this.props,
                    ...(withRef ? { ref: this.setWrappedInstance } : {})
                };
                return (
                    <I18n update={update} withHash={withHash}>
                        {({ i18n, i18nHash }) => (
                            <WrappedComponent
                                {...props}
                                i18n={i18n}
                                i18nHash={i18nHash}
                            />
                        )}
                    </I18n>
                );
            }
        }

        return hoistStatics(WithI18n, WrappedComponent);
    };

// match <0>paired</0> and <1/> unpaired tags
const tagRe = /<(\d+)>(.*?)<\/\1>|<(\d+)\/>/;
const nlRe = /(?:\r\n|\r|\n)/g;

/**
 * `formatElements` - parse string and return tree of react elements
 *
 * `value` is string to be formatted with <0>Paired<0/> or <0/> (unpaired)
 * placeholders. `elements` is a array of react elements which indexes
 * correspond to element indexes in formatted string
 */
function formatElements(value, elements = []) {
    // TODO: warn if there're any unprocessed elements
    // TODO: warn if element at `index` doesn't exist

    const parts = value.replace(nlRe, '').split(tagRe);

    // no inline elements, return
    if (parts.length === 1) return value;

    const tree = [];

    const before = parts.shift();
    if (before) tree.push(before);

    for (const [index, children, after] of getElements(parts)) {
        const element = elements[index];
        tree.push(
            cloneElement(
                element,
                { key: index },

                // format children for pair tags
                // unpaired tags might have children if it's a component passed as a variable
                children
                    ? formatElements(children, elements)
                    : element.props.children
            )
        );

        if (after) tree.push(after);
    }

    return tree;
}

/*
 * `getElements` - return array of element indexes and element childrens
 *
 * `parts` is array of [pairedIndex, children, unpairedIndex, textAfter, ...]
 * where:
 * - `pairedIndex` is index of paired element (undef for unpaired)
 * - `children` are children of paired element (undef for unpaired)
 * - `unpairedIndex` is index of unpaired element (undef for paired)
 * - `textAfter` is string after all elements (empty string, if there's nothing)
 *
 * `parts` length is always multiply of 4
 *
 * Returns: Array<[elementIndex, children, after]>
 */
function getElements(parts) {
    if (!parts.length) return [];

    const [paired, children, unpaired, after] = parts.slice(0, 4);

    return [[parseInt(paired || unpaired), children || '', after]].concat(
        getElements(parts.slice(4, parts.length))
    );
}

class Render extends React.Component {
    static contextTypes = {
        linguiDefaultRender: PropTypes.any
    };

    render() {
        const { className, value, render: renderProp } = this.props || {};
        let render = renderProp || this.context.linguiDefaultRender;

        if (render === null || render === undefined) {
            return value || null;
        } else if (typeof render === 'string') {
            // Built-in element: h1, p
            return React.createElement(render, { className }, value);
        }

        return React.isValidElement(render)
            ? // Custom element: <p className="lear' />
              React.cloneElement(render, {}, value)
            : // Custom component: ({ translation }) => <a title={translation}>x</a>
              React.createElement(render, { translation: value });
    }
}

class Trans extends React.Component {
    props;

    componentDidMount() {
        const { children } = this.props || {};
        if (process.env.NODE_ENV !== 'production') {
            if (!this.getTranslation() && children) {
                console.warn(
                    '@lingui/babel-preset-react is probably missing in babel config, ' +
                        'but you are using <Trans> component in a way which requires it. ' +
                        "Either don't use children in <Trans> component or configure babel " +
                        'to load @lingui/babel-preset-react preset. See tutorial for more info: ' +
                        'https://l.lingui.io/tutorial-i18n-react'
                );
            }
        }
    }

    getTranslation() {
        const {
            id = '',
            defaults,
            i18n,
            formats,
            values: valuesProp = {},
            components: componentsProp
        } = this.props || {};

        const values = { ...valuesProp };
        const components = componentsProp ? [...componentsProp] : [];

        if (values) {
            /*
            Related discussion: https://github.com/lingui/js-lingui/issues/183
      
            Values *might* contain React elements with static content.
            They're replaced with <INDEX /> placeholders and added to `components`.
      
            Example:
            Translation: Hello {name}
            Values: { name: <strong>Jane</strong> }
      
            It'll become "Hello <0 />" with components=[<strong>Jane</strong>]
            */

            Object.keys(values).forEach(key => {
                const value = values[key];
                if (!React.isValidElement(value)) return;

                const index = components.push(value) - 1; // push returns new length of array
                values[key] = `<${index}/>`;
            });
        }

        const translation =
            i18n && typeof i18n._ === 'function'
                ? i18n._(id, values, { defaults, formats })
                : id; // i18n provider isn't loaded at all
        if (!translation) return null;

        return formatElements(translation, components);
    }

    render() {
        const { render, className } = this.props || {};
        return (
            <Render
                render={render}
                className={className}
                value={this.getTranslation()}
            />
        );
    }
}

var Trans$1 = withI18n()(Trans);

const Select = withI18n()(
    class Select extends React.Component {
        render() {
            // lingui-transform-js transforms also this file in react-native env.
            // i18n must be aliased to _i18n to hide i18n.select call from plugin,
            // otherwise it throws "undefined is not iterable" obscure error.
            const { className, render, i18n: _i18n, ...selectProps } =
                this.props || {};
            return (
                <Render
                    className={className}
                    render={render}
                    value={_i18n.select(selectProps)}
                />
            );
        }
    }
);

const PluralFactory = (ordinal = false) => {
    const displayName = !ordinal ? 'Plural' : 'SelectOrdinal';

    return class extends React.Component {
        displayName = displayName;

        static defaultProps = {
            offset: 0
        };

        render() {
            const { className, render, i18n, value, offset, ...props } =
                this.props || {};
            const getPluralValue = !ordinal ? i18n.plural : i18n.selectOrdinal;

            // i18n.selectOrdinal/plural uses numbers for exact matches (1, 2),
            // while SelectOrdinal/Plural has to use strings (_1, _2).
            const pluralProps = Object.keys(props).reduce(
                (acc, prop) => {
                    const key = prop.replace('_', '');
                    acc[key] = props[prop];
                    return acc;
                },
                {
                    value: Number(value),
                    offset: Number(offset)
                }
            );

            return (
                <Render
                    className={className}
                    render={render}
                    value={getPluralValue(pluralProps)}
                />
            );
        }
    };
};

const Plural = withI18n()(PluralFactory(false));
const SelectOrdinal = withI18n()(PluralFactory(true));

function createFormat(formatFunction) {
    return function({ value, format, i18n, className, render }) {
        const formatter = formatFunction(i18n.locales || i18n.language, format);
        return (
            <Render
                className={className}
                render={render}
                value={formatter(value)}
            />
        );
    };
}

const DateFormat = withI18n()(createFormat(date));
const NumberFormat = withI18n()(createFormat(number));

const i18nMark = id => id;

export {
    i18nMark,
    withI18n,
    I18nProvider,
    I18n,
    Trans$1 as Trans,
    Plural,
    Select,
    SelectOrdinal,
    DateFormat,
    NumberFormat
};
