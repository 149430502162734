// @flow
import { compileParamsToPath } from '@ludwig-forked/rudy-match-path';
import type {
    Route,
    Payload,
    Params,
    RoutesMap,
    ReceivedAction as Action,
    QuerySerializer
} from '../flow-types';

export default (
    action: Action,
    routesMap: RoutesMap,
    serializer?: QuerySerializer
): string => {
    // console.log('REDUX FIRST - Action To Path: ', compileParamsToPath)
    const route = routesMap[action.type];
    const routePath = typeof route === 'object' ? route.path : route;
    // console.log('REDUX FIRST - Action To Path - Route Path ', routePath)
    const options = typeof route === 'object' ? route.options : {};
    const params = _payloadToParams(route, action.payload);
    // console.log('REDUX FIRST - Action To Path - Params ', params)
    // console.log('REDUX FIRST - Options ', options)
    const path = compileParamsToPath(routePath, params, options) || '/';
    // console.log('REDUX FIRST - Action To Path: ', path)
    const query =
        action.query ||
        (action.meta && action.meta.query) ||
        (action.payload && action.payload.query);

    const search = query && serializer && serializer.stringify(query);

    return search ? `${path}?${search}` : path;
};

const _payloadToParams = (route: Route, params: Payload = {}): Params =>
    Object.keys(params).reduce((sluggifedParams, key) => {
        const segment = params[key];
        sluggifedParams[key] = transformSegment(segment, route, key);
        // log.debug('CUSTOM RFR - Action To Path - Payload To Params - Params ', sluggifedParams)
        return sluggifedParams;
    }, {});

const transformSegment = (segment: string, route: Route, key: string) => {
    if (typeof route.toPath === 'function') {
        return route.toPath(segment, key);
    } else if (typeof segment === 'string') {
        if (segment.indexOf('/') > -1) {
            return segment.split('/');
        }

        if (route.capitalizedWords === true) {
            return segment.replace(/ /g, '-').toLowerCase();
        }

        return segment;
    } else if (typeof segment === 'number') {
        return segment;
    }
};
