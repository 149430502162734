// @flow

import type {
    Options
} from './flow-types';

/** SIDE EFFECTS:
 *  Client code needs a simple `push`,`back` + `next` functions because it's convenient for
 *  prototyping. It will not harm SSR, so long as you don't use it server side. So if you use it, that means DO NOT
 *  simulate clicking links server side--and dont do that, dispatch actions to setup state instead.
 *
 *  THE IDIOMATIC WAY: instead use https://github.com/faceyspacey/redux-first-router-link 's `<Link />`
 *  component to generate SEO friendly urls. As its `href` prop, you pass it a path, array of path
 *  segments or action, and internally it will use `connectRoutes` to change the address bar and
 *  dispatch the correct final action from middleware.
 *
 *  NOTE ON BACK FUNCTIONALITY: The better way to accomplish a back button is to use your redux state to determine
 *  the previous URL. The location reducer will also contain relevant info. But if you must,
 *  this is here for convenience and it basically simulates the user pressing the browser
 *  back button, which of course the system picks up and parses into an action.
 */

let _history;
let _scrollBehavior;
let _updateScroll;
let _selectLocationState;
let _options;

export const push = (pathname: string) => _history.push(pathname);

export const replace = (pathname: string) => _history.replace(pathname);

export const back = () => _history.goBack();

export const next = () => _history.goForward();

export const go = (n: number) => _history.go(n);

export const canGo = (n: number) => _history.canGo(n);

export const canGoBack = (): boolean =>
    !!(_history.entries && _history.entries[_history.index - 1]);

export const canGoForward = (): boolean =>
    !!(_history.entries && _history.entries[_history.index + 1]);

export const prevPath = (): ?string => {
    const entry = _history.entries && _history.entries[_history.index - 1];
    return entry && entry.pathname;
};

export const nextPath = (): ?string => {
    const entry = _history.entries && _history.entries[_history.index + 1];
    return entry && entry.pathname;
};

export const history = () => _history;

export const scrollBehavior = () => _scrollBehavior;

export const updateScroll = () => _updateScroll && _updateScroll();

export const selectLocationState = (state: Object) => {
    return typeof _selectLocationState === 'function'
        ? _selectLocationState(state)
        : _selectLocationState
        ? state => state[_selectLocationState]
        : state => state.location;
};

export const getHistory = () => _history
export const getScrollBehavior = () => _scrollBehavior
export const getUpdateScroll = () => _updateScroll
export const getSelectLocationState = () => _selectLocationState
export const getOptions = (): Options => _options || {};


export const setHistory = (history) => _history = history
export const setScrollBehavior = (scrollBehavior) => _scrollBehavior = scrollBehavior
export const setUpdateScroll = (updateScroll) => _updateScroll = updateScroll
export const setSelectLocationState = (selectLocationState) => _selectLocationState = selectLocationState
export const setOptions = (options) => _options = options

